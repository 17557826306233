<template>
  <div style="width: 70%">
    <div class="login-form login-signin">
      <div class="text-center mb-10 mb-lg-5">
        <h3 class="font-size-h2 font-weight-900">
          Chào mừng đến với N-Core 👋
        </h3>
        <p class="text-muted font-weight-semi-bold">
          Vui lòng nhập tên đăng nhập và mật khẩu theo tài khoản N-Core
        </p>
      </div>

      <div class="login-form-container mt-20 p-10">
        <p class="font-size-h2 text-center font-weight-900">
          {{ step[mode].title }}
        </p>
        <b-form class="form" @submit.stop.prevent="onLogin">
          <div
            v-if="errors"
            role="alert"
            v-bind:class="{ show: errors && errors.length }"
            class="alert fade alert-danger"
          >
            <div class="alert-text">
              {{ errors }}
            </div>
          </div>
          <transition name="fade-in-up">
            <div>
              <LoginUsername
                v-if="mode === 'welcome'"
                :ref="step.welcome.ref"
                :username="username"
              />
              <ResetPassword
                v-if="mode === 'password'"
                :ref="step.password.ref"
                :username="username"
                :token="token"
              />
              <ForgotPassword
                v-if="mode === 'forgot-password'"
                :ref="step['forgot-password'].ref"
                :username="username"
                v-on:change-mode="onEmitChangeMode"
                v-on:update-username="onEmitUpdateUsername"
              />
              <FailedPassword
                v-if="mode === 'failed-password'"
                :username="username"
                v-on:change-mode="onEmitChangeMode"
              />
            </div>
          </transition>
          <div
            class="form-group d-flex flex-wrap justify-content-between align-items-center"
          >
            <button
              v-if="['welcome', 'password'].includes(mode)"
              ref="login_submit"
              class="submit-button w-100 btn font-weight-bold px-9 py-4 my-3 font-size-3"
            >
              {{ step[mode].titleBtn }}
            </button>
            <a
              v-if="mode === 'welcome'"
              href="javascript:void(0)"
              class="d-block text-center w-100"
              @click="onEmitChangeMode('forgot-password')"
              >Bạn quên mật khẩu ?</a
            >
          </div>
          <p class="mt-5 text-center">
            Vui lòng liên hệ IT nếu chưa có tài khoản N-Core
          </p>
        </b-form>
      </div>
    </div>
  </div>
</template>
  
  <script>
import {
  LOGIN,
  LOGOUT,
  RESET_PASSWORD,
  TOKEN_RESET_PASSWORD,
} from '@/core/services/store/modules/auth.module';
import { mapState } from 'vuex';
import { TIME_TRIGGER } from '@/utils/constants';
import { validationMixin } from 'vuelidate';
import LoginUsername from '@/view/components/auth/LoginUsername.vue';
import ResetPassword from '@/view/components/auth/ResetPassword.vue';
import ForgotPassword from '@/view/components/auth/ForgotPassword.vue';
import FailedPassword from '@/view/components/auth/FailedPassword.vue';

export default {
  mixins: [validationMixin],
  name: 'login',
  data() {
    return {
      firstTimeLogin: false,
      headerTitle: 'Chào mừng đến với N-Core 👋',
      mode: 'welcome',
      step: {
        welcome: {
          headerTitle: this.headerTitle,
          title: 'Đăng nhập',
          titleBtn: 'Đăng nhập',
          ref: 'welcome-user-name',
          url: LOGIN,
        },
        password: {
          headerTitle: 'Vui lòng thay đổi mật khẩu 👋',
          title: 'Thay đổi mật khẩu',
          titleBtn: 'Thay đổi mật khẩu',
          ref: 'change-password',
          url: RESET_PASSWORD,
        },
        otp: {
          headerTitle: this.headerTitle,
          title: 'Nhập mã otp của bạn',
          ref: 'welcome-otp',
        },
        'forgot-password': {
          headerTitle: this.headerTitle,
          title: 'Lấy lại mật khẩu?',
          titleBtn: 'Gửi mail',
          ref: 'forgot-password',
        },
        'failed-password': {
          headerTitle: this.headerTitle,
          title: 'Có lỗi xảy ra',
          titleBtn: 'Trở về',
          ref: 'failed-password',
        },
      },
      submitButton: null,
      username: null,
      token: null,
    };
  },
  components: {
    LoginUsername,
    ResetPassword,
    ForgotPassword,
    FailedPassword,
  },
  validations: {},
  created() {
    this.checkAuthUser();
    const type = this.$route.query.type || null;
    const username = this.$route.query.username || null;
    const token = this.$route.query.token || null;
    switch (type) {
      case 'change-password': {
        this.mode = 'password';
        this.username = username;
        this.checkToken(token);
        break;
      }
      default:
        break;
    }
  },
  methods: {
    isFromEmail() {
      return this.$route.query.token;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validateResetState(name) {
      const { $dirty, $error } = this.$v.resetpassword[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        username: null,
        password: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    updateStep(data) {
      this.firstTimeLogin = data.firstTimeLogin;
    },
    catchAnyError(mode) {
      this.$v[mode].$touch();
      return this.$v[mode].$anyError;
    },
    getElementSubmit() {
      return this.$refs['login_submit'];
    },
    onLoadingSubmit() {
      this.submitButton = this.getElementSubmit();
      this.submitButton.classList.add(
        'spinner',
        'spinner-light',
        'spinner-right',
      );
    },
    unLoadingSubmit() {
      this.submitButton.classList.remove(
        'spinner',
        'spinner-light',
        'spinner-right',
      );
    },
    onLogin() {
      let data = {};
      let error = true;
      switch (this.mode) {
        case 'welcome': {
          error = this.$refs[this.step.welcome.ref].catchAnyError();
          data = this.$refs[this.step.welcome.ref].welcome;
          break;
        }
        case 'password': {
          data.token = null;
          error = this.$refs[this.step.password.ref].catchAnyError();
          const { changePassword } = this.$refs[this.step.password.ref];
          data.username = this.username;
          data.password = changePassword.newpassword;
          data.token = this.$route.query.token || null;
          break;
        }
        case 'otp': {
          this.$refs[this.step.otp.ref].catchAnyError();
          break;
        }
        default:
          break;
      }
      if (error) return;

      this.$store.dispatch(LOGOUT);
      this.onLoadingSubmit();

      this.$store
        .dispatch(this.step[this.mode].url, data)
        .then((result) => {
          switch (this.mode) {
            case 'welcome': {
              if (result.isNextPassword) {
                this.mode = 'password';
                this.username = result.username;
              } else {
                this.$router.push({ name: 'list-products' });
                setTimeout(() => {
                  this.$router.go(this.$router.currentRoute);
                }, TIME_TRIGGER);
              }
              break;
            }
            case 'password': {
              if (result.username) {
                this.mode = 'welcome';
                this.$refs[this.step.welcome.ref].welcome.username =
                  result.username;
              }
              break;
            }
            case 'otp':
              break;
            default:
              break;
          }
          this.unLoadingSubmit();
        })
        .catch(() => {
          this.unLoadingSubmit();
        });
    },
    checkAuthUser() {
      if (this.isAuthenticated && !this.isFromEmail()) {
        this.$router.push({ name: 'dashboard' });
      }
    },
    onEmitChangeMode(mode) {
      this.mode = mode;
    },
    onEmitUpdateUsername(payload) {
      this.username = payload ? payload.username : null;
    },
    checkToken(token) {
      this.$store
        .dispatch(TOKEN_RESET_PASSWORD, {
          token: token,
          username: this.username,
        })
        .then((result) => {
          console.log('result', result);
        })
        .catch(() => {
          this.mode = 'failed-password';
        });
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
      isAuthenticated: (state) => state.auth.isAuthenticated,
    }),
  },
};
</script>
  
  <style lang="scss" scoped>
.login-form-container {
  height: 500px;
  border-radius: 14px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.submit-button {
  color: white;
  background-color: #bd1e2d;
  cursor: pointer;
  transition: 0.3s;
}
.submit-button:hover {
  background-color: #de2437;
}
.font-weight-900 {
  font-weight: 900;
}
</style>
  