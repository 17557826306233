<template>
  <div>
    <b-form-group
      :state="validateState('newpassword')"
      :invalid-feedback="getInvalidFeedback($v.changePassword, 'newpassword')"
    >
      <b-form-input
        class="form-control form-control-solid h-auto py-5 px-6"
        type="password"
        v-model="$v.changePassword.newpassword.$model"
        :state="validateState('newpassword')"
        placeholder="Mật khẩu mới"
      />
    </b-form-group>

    <b-form-group
      :state="validateState('confirmpassword')"
      :invalid-feedback="
        getInvalidFeedback($v.changePassword, 'confirmpassword')
      "
    >
      <b-form-input
        class="form-control form-control-solid h-auto py-5 px-6"
        type="password"
        v-model="$v.changePassword.confirmpassword.$model"
        placeholder="Xác thực mật khẩu mới"
      />
    </b-form-group>
  </div>
</template>
  
  <script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  props: {
    username: {
      required: true,
      type: String,
    },
  },
  mixins: [validationMixin],
  data() {
    return {
      changePassword: {
        newpassword: '',
        confirmpassword: '',
      },
      notMatch: true,
    };
  },
  validations: {
    changePassword: {
      newpassword: {
        required,
      },
      confirmpassword: {
        required,
        notMatch() {
          return this.notMatch;
        },
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.changePassword[name];
      return $dirty ? !$error : null;
    },
    catchAnyError() {
      this.notMatch = true;
      const newpassword = this.$v.changePassword.newpassword.$model;
      const confirmpassword = this.$v.changePassword.confirmpassword.$model;
      if (newpassword !== confirmpassword) {
        this.notMatch = false;
      }
      this.$v.changePassword.$touch();
      return this.$v.changePassword.$anyError;
    },
    getInvalidFeedback(validation, field) {
      if (!validation[field].$dirty) return '';

      if (field === 'newpassword' && !validation[field].required) {
        return 'Vui lòng nhập mật khẩu mới';
      }
      if (field === 'confirmpassword' && !validation[field].required) {
        return 'Vui lòng nhập lại mật khẩu';
      }
      if (!validation[field].notMatch) return 'Mật khẩu không khớp';
      return '';
    },
  },
};
</script>