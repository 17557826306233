<template>
  <div>
    <div v-if="currentStep === 'one'">
      <b-form-group
        label="Lấy lại mật khẩu bằng:"
        label-class="font-weight-bolder"
      >
        <b-form-radio v-model="reset.type" value="email"
          >Địa chỉ email</b-form-radio
        >
        <b-form-radio v-model="reset.type" value="mobile"
          >Số điện thoại</b-form-radio
        >
      </b-form-group>
    </div>
    <div v-if="['two.email', 'two.mobile'].includes(currentStep)">
      <b-form-group
        :state="validateState('emailOrMobile')"
        :invalid-feedback="getInvalidFeedback($v.reset.emailOrMobile)"
      >
        <b-form-input
          class="form-control form-control-solid h-auto py-5 px-6"
          v-model="$v.reset.emailOrMobile.$model"
          :placeholder="getPlaceHolder()"
        ></b-form-input>
      </b-form-group>
    </div>

    <div v-if="currentStep === 'two.mobile.otp'">
      <span class="mobile-text"
        ><b class="mr-1">Nhập mã otp được gửi về số điện thoại</b>
        <b class="text-primary">******526</b>
      </span>
      <div class="d-flex flex-row mt-5">
        <div v-for="(el, index) in reset.otp" :key="el + index" class="mr-2">
          <input
            v-if="index === 0"
            type="text"
            class="digit-box form-control text-center mr-2"
            v-model="reset.otp[index]"
            autofocus=""
            :placeholder="index + 1"
            maxlength="1"
            @keydown="handleKeyDown($event, index)"
          />
          <input
            v-if="index !== 0"
            type="text"
            class="digit-box form-control text-center mr-2"
            v-model="reset.otp[index]"
            :placeholder="index + 1"
            maxlength="1"
            @keydown="handleKeyDown($event, index)"
          />
        </div>
      </div>
      <div class="text-center mt-5">
        <span class="d-block mobile-text" id="countdown"></span>
        <span class="d-block mobile-text" id="resend"></span>
      </div>
    </div>
    <div v-if="currentStep === 'final'">
      <b-form-group
        label="Thông tin đăng nhập đã được gửi về mail"
        label-class="font-weight-bolder"
      >
        <div>
          <i
            class="fa-solid fa-circle-check text-success align-item-center mr-1"
          ></i>
          <span class="align-item-center"> {{ reset.emailOrMobile }}</span>
        </div>
      </b-form-group>
    </div>
    <div class="form-group d-flex justify-content-between align-items-center">
      <b-button
        v-if="currentStep !== 'final'"
        class="d-inline font-weight-bold form-control h-100 px-9 py-4 my-3 mr-2 font-size-3"
        @click="onPreviousStep(currentStep)"
      >
        Trở về
      </b-button>
      <b-button
        class="d-inline submit-button form-control h-100 font-weight-bold px-9 py-4 my-3 font-size-3"
        @click="onNextStep(currentStep)"
      >
        {{ step[currentStep].btnTitle }}
      </b-button>
    </div>
  </div>
</template>
  
  <script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { SEND_RESET_PASSWORD } from '@/core/services/store/modules/auth.module';
export default {
  props: {},
  mixins: [validationMixin],
  data() {
    return {
      reset: {
        type: 'email',
        emailOrMobile: '',
        otp: ['', '', '', '', '', ''],
        currentStep: '',
      },
      validEmailMobile: true,
      step: null,
      currentStep: 'one',
    };
  },
  validations: {
    reset: {
      emailOrMobile: {
        required,
        validEmailMobile: function (value) {
          return this.isValidEmailAndMobile(value);
        },
      },
    },
  },
  created() {
    this.step = this.getStep();
  },
  methods: {
    getStep() {
      return {
        one: {
          btnTitle: 'Kế tiếp',
          nextStep: `two.{type}`,
          previousStep: '',
        },
        'two.email': {
          btnTitle: 'Kế tiếp',
          step: 'email',
          async: true,
          nextStep: 'final',
          previousStep: 'one',
        },
        'two.mobile': {
          btnTitle: 'Kế tiếp',
          step: 'mobile',
          async: true,
          nextStep: 'two.mobile.otp',
          previousStep: 'one',
        },
        'two.mobile.otp': {
          btnTitle: 'Kế tiếp',
          step: 'mobile',
          async: true,
          nextStep: ' ',
          previousStep: 'two.mobile',
        },
        final: {
          btnTitle: 'Trở về',
          nextStep: '',
        },
      };
    },
    isValidEmailAndMobile(value) {
      if (this.isType('email')) {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(value);
      }
      if (this.isType('mobile')) {
        const regex = /^(03|05|07|08|09)+([0-9]{8})$/;
        return regex.test(value);
      }
    },
    getInvalidFeedback(validation) {
      if (!validation.$dirty) return '';
      if (!validation.required) {
        return `Vui lòng nhập ${
          this.isType('email') ? ` địa chỉ email` : ` số điện thoại`
        }`;
      }
      if (!validation.validEmailMobile) {
        return `${
          this.isType('email')
            ? `Địa chỉ email không hợp lệ`
            : `Số điện thoại không hợp lệ`
        }`;
      }

      return '';
    },
    isType(type) {
      return this.reset.type === type;
    },
    getPlaceHolder() {
      if (this.isType('email')) {
        return 'Vui lòng nhập email';
      }
      if (this.isType('mobile')) {
        return 'Vui lòng nhập số điện thoại';
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.reset[name];
      return $dirty ? !$error : null;
    },
    catchAnyError() {
      this.$v.reset.$touch();
      return this.$v.reset.$anyError;
    },
    onNextStep(currentStep) {
      const step = this.step[currentStep];
      const hasNext = step ? step.nextStep : false;
      if (!hasNext) {
        this.$emit('change-mode', 'welcome');
        return;
      }
      const nextStep = step.nextStep.replace(`{type}`, this.reset.type);
      if (!step.async) {
        this.currentStep = nextStep;
        return;
      }
      const url = ['two.email', 'two.mobile', 'two.mobile.otp'].includes(
        currentStep,
      )
        ? SEND_RESET_PASSWORD
        : '';

      if (url) {
        this.reset.currentStep = currentStep;
        this.onSubmit(url, currentStep, nextStep);
      }
    },
    onPreviousStep(currentStep) {
      const step = this.step[currentStep];
      const hasPrevious = step ? step.previousStep : false;
      if (!hasPrevious) {
        this.$emit('change-mode', 'welcome');
        return;
      }
      this.currentStep = hasPrevious;
    },
    onSubmit(url, currentStep, nextStep) {
      this.$store
        .dispatch(url, {
          ...this.reset,
          otp: this.reset.otp
            ? this.reset.otp.toString().replaceAll(',', '')
            : '',
        })
        .then((result) => {
          if (currentStep === 'two.mobile.otp') {
            this.$emit('update-username', {
              username: result.username,
            });
            this.$emit('change-mode', 'password');
          } else {
            this.currentStep = nextStep;
          }
        });
    },
    handleKeyDown(event, index) {
      if (event.key === 'F12') return;

      if (event.key === 'Backspace') {
        this.reset.otp[index] = null;

        if (index !== 0) {
          this.$refs['otpCont'][index + 1].focus();
        }

        return;
      }
      if (new RegExp('^([0-9])$').test(event.key)) {
        this.reset.otp[index] = event.key;
        if (index !== 5) {
          this.$refs['otpCont'][index + 1].focus();
        }
      } else {
        if (
          event.key !== 'Tab' &&
          event.key !== 'ArrowRight' &&
          event.key !== 'ArrowLeft'
        ) {
          event.preventDefault();
        }
      }
    },
  },
};
</script>
<style scoped>
.submit-button {
  color: white;
  background-color: #bd1e2d;
  cursor: pointer;
  transition: 0.3s;
}
.submit-button:hover {
  background-color: #de2437;
}
</style>