<template>
  <div>
    <div class="form-group d-flex justify-content-between align-items-center">
      <b-button
        class="d-inline font-weight-bold form-control h-100 px-9 py-4 my-3 mr-2 font-size-3"
        @click="$emit('change-mode', 'welcome')"
      >
        Trở về
      </b-button>
    </div>
  </div>
</template>
  
<script>
import { validationMixin } from 'vuelidate';
import { minLength, required } from 'vuelidate/lib/validators';

export default {
  props: {},
  mixins: [validationMixin],
  data() {
    return {
      welcome: {
        username: '',
        password: '',
      },
    };
  },
  validations: {
    welcome: {
      username: {
        required,
        minLength: minLength(3),
      },
      password: {
        required,
        minLength: minLength(3),
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.welcome[name];
      return $dirty ? !$error : null;
    },
    catchAnyError() {
      this.$v.welcome.$touch();
      return this.$v.welcome.$anyError;
    },
  },
};
</script>