<template>
  <div>
    <b-form-group>
      <b-form-input
        class="form-control form-control-solid h-auto py-5 px-6"
        v-model="$v.welcome.username.$model"
        :state="validateState('username')"
        placeholder="Tên đăng nhập"
      ></b-form-input>

      <b-form-invalid-feedback class="mt-2 ml-1">
        Tên đăng nhập không được để trống.
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group>
      <b-form-input
        class="form-control form-control-solid h-auto py-5 px-6"
        type="password"
        v-model="$v.welcome.password.$model"
        :state="validateState('password')"
        aria-describedby="password-feedback"
        placeholder="Mật khẩu"
      ></b-form-input>

      <b-form-invalid-feedback id="password-feedback" class="mt-2 ml-1">
        Mật khẩu không được để trống.
      </b-form-invalid-feedback>
    </b-form-group>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { minLength, required } from 'vuelidate/lib/validators';

export default {
  props: {},
  mixins: [validationMixin],
  data() {
    return {
      welcome: {
        username: '',
        password: '',
      },
    };
  },
  validations: {
    welcome: {
      username: {
        required,
        minLength: minLength(3),
      },
      password: {
        required,
        minLength: minLength(3),
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.welcome[name];
      return $dirty ? !$error : null;
    },
    catchAnyError() {
      this.$v.welcome.$touch();
      return this.$v.welcome.$anyError;
    },
  },
};
</script>